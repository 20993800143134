import React from "react"

var layout = require("../layout.module.css");
var style = require("./footer.module.css");

export default class Footer extends React.Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <div className={[layout.footer, style.footer].join(' ')}>
        <div className={style.slogan}>Always <span className={style.green}>green.</span> never grumpy.</div>
        <div className={style.content}>
          <div className={style.links}>
            <a className={style.link} href="https://www.instagram.com/strawberryearth" target="_blank" rel="noopener noreferrer">Instagram</a>
            <a className={style.link} href="https://us2.list-manage.com/subscribe/post?u=bdae9df51dbd0cbfe9aa2f4f4&id=1af51d3058" target="_blank" rel="noopener noreferrer">Nieuwsbrief</a>
          </div>
          <div className={style.copyright}>
            &copy; {year} all rights reserved Strawberry Earth
          </div>
        </div>
      </div>
    )
  }
}