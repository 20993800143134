// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-project-index-js": () => import("/opt/build/repo/src/components/Project/index.js" /* webpackChunkName: "component---src-components-project-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opdrachtgevers-index-js": () => import("/opt/build/repo/src/pages/opdrachtgevers/index.js" /* webpackChunkName: "component---src-pages-opdrachtgevers-index-js" */),
  "component---src-pages-werk-index-js": () => import("/opt/build/repo/src/pages/werk/index.js" /* webpackChunkName: "component---src-pages-werk-index-js" */)
}

