import React from 'react'
import Navigation from "./Navigation/navigation.js"
import Footer from "./Footer/footer.js"

import "./index.css"
var layoutStyle = require("./layout.module.css");

export default ({ children }) => (
  <div className={layoutStyle.wrapper}>
    <Navigation />
    <div id="contentContainer" className={layoutStyle.content}>
      {children}
    </div>
    <Footer />
  </div>
)