import React from "react"
import { Link } from "gatsby"
import logo from "../../assets/logo_black.svg"
// import menu from "../../assets/logo_black.svg"
// import close from "../../assets/logo_white.svg"

var layout = require("../layout.module.css");
var style = require("./navigation.module.css");

export default class Navigation extends React.Component {


  constructor(props) {
    super(props)
    this.handleClickMobileMenuOpen = this.handleClickMobileMenuOpen.bind(this);
    this.handleClickMobileMenuClose = this.handleClickMobileMenuClose.bind(this);
    this.handleClickMobileMenu = this.handleClickMobileMenu.bind(this);
    this.state = {
      mobileMenu: false,
    }
  }

  handleClickMobileMenuOpen(e) {
    e.preventDefault()
    this.setState({
      mobileMenu: true,
    })
  }

  handleClickMobileMenuClose(e) {
    e.preventDefault()
    this.setState({
      mobileMenu: false,
    })
  }

  handleClickMobileMenu(e) {
    this.setState({
      mobileMenu: false,
    })
  }

  render() {
    // const menuClass = this.state.mobileMenu ? `${style.header} ${style.menuStateOpen}` : `${style.header}`;
    return (
      <div className={layout.navigation}>
        <div className={style.navContainer}>
          <Link to="/">
            <img className={style.logo} src={logo} width="150" alt="Strawberry Earth" />
          </Link>
          <div className={style.menu} onClick={this.handleClickMobileMenu}>
            <Link className={style.link} activeClassName={style.linkActive} to="/">Home</Link>
            {/* <Link className={style.link} activeClassName={style.linkActive} partiallyActive={true} to="/over/">Over ons</Link> */}
            <Link className={style.link} activeClassName={style.linkActive} partiallyActive={true} to="/werk/">Werk</Link>
            <Link className={style.link} activeClassName={style.linkActive} partiallyActive={true} to="/opdrachtgevers/">Opdrachtgevers</Link>
            {/* <Link className={style.link} activeClassName={style.linkActive} partiallyActive={true} to="/contact/">Contact</Link> */}
            {/* <Link className={style.link} activeClassName={style.linkActive} partiallyActive={true} to="/media/">In de media</Link> */}
          </div>
          {/*
          <div className={style.menuOpen} onClick={this.handleClickMobileMenuOpen}>
            <div className={style.menuBackgroundContainer}>
              <img className={style.menuBackground} src={menu} width="80"  alt="Open navigation" />
            </div>
          </div>
          <div className={style.menuClose} onClick={this.handleClickMobileMenuClose}>
            <div className={style.menuBackgroundContainer}>
              <img className={style.menuBackground} src={close} width="80"  alt="Close navigation"/>
            </div>
          </div>
          */}
        </div>
      </div>
    )
  }
}